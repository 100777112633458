import React from 'react'
import { AboutProps } from './types'
import { AboutTextSection, AboutTitleSection } from './styles'
import { BodyLarge, BodyMedium, H2 } from '../shared'
import { useTheme } from 'styled-components'
import AboutImageOne from '../../assets/about-image-one.png';
import AboutImageTwo from '../../assets/about-image-two.png';

const About = (props: AboutProps) => {

    const theme = useTheme()
    return <>
        <AboutTitleSection id='about-section'>
            <div>
                <H2>About</H2><H2 style={{ color: theme.colors.tertiary }}>Floating Fog</H2>
            </div>
        </AboutTitleSection>
        <AboutTextSection>
            <div>
                <div>
                    <BodyLarge>
                        At Floating Fog, we are passionate about transforming ideas into impact software solutions. Our mission is to deliver high-quality applications that not only meet but exceed our clients' expectations. We believe in the power of collaboration and strive to bring out the best in every project we undertake.Our team is a dynamic blend of creative thinkers, skilled developers, and dedicated professionals, all working together to turn visionary concepts into reality. We thrive on challenges and are committed to pushing the boundaries of technology to create software that makes a difference.
                    </BodyLarge>
                    <BodyLarge>
                        Our team is a dynamic blend of creative thinkers, skilled developers, and dedicated professionals, all working together to turn visionary concepts into reality. We thrive on challenges and are committed to pushing the boundaries of technology to create software that makes a difference.
                    </BodyLarge>
                </div>
                <img src={AboutImageOne} />
            </div>
        </AboutTextSection >
        <AboutTextSection style={{ backgroundColor: theme.colors.tertiary }}>
            <div style={{ paddingBottom: '0px' }}>
                <img src={AboutImageTwo} />
                <div>
                    <BodyLarge>
                        Whether you are a startup looking to make your mark or an established business aiming to innovate, Floating Fog is here to help you navigate the digital landscape. We take pride in our ability to understand your unique needs and deliver tailored solutions that drive success.
                    </BodyLarge>
                    <BodyLarge>
                        Join us on our journey to make the world a better place, one application at a time. At Floating Fog, your vision is our mission.
                    </BodyLarge>
                </div>
            </div>
        </AboutTextSection>
    </>

}

export default About