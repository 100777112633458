import styled from "styled-components";
import { Section } from "../shared";
import customMedia from "../../utils/media";

export const HeroSection = styled(Section)`
    background-color: ${(props) => props.theme.colors.secondary};
`

export const HeroContainer = styled.div`
    max-width: 1500px; 
    display: flex; 
    min-height: 100vh;
    flex-direction: row;
    padding-horizontal;
    align-items: center;
    justify-content: center; 

    ${customMedia.lessThan('lg')`
        flex-direction: column;
        align-items: flex-start;
        gap: 100px;

        > div {
            justify-content: center;
        }
    `}

    
    ${customMedia.lessThan('md')`
        align-items: center; 
`}

`;

export const TextContainer = styled.div`
    display: flex; 
    flex-direction: column; 
    gap: 25px; 
`

export const HeroGraphic = styled.img`
    max-width: 80%;
    max-height: 500px;
`
