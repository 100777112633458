import React from 'react';
import logo from './logo.svg';
import './App.css';
import styled from 'styled-components';
import Hero from './components/Hero';
import About from './components/About';
import Team from './components/Team';


const AppContainer = styled.div`
  background-color: ${props => props.theme.colors.secondary}
`

function App() {
  return (
    <AppContainer>
      <Hero />
      <About />
      <Team />
    </AppContainer>
  );
}

export default App;
