import { TeamContentSection, TeamMember as TeamMemberComponent, TeamsTitleSection } from './styles'
import { BodyLarge, H2, TitleMedium } from '../shared'
import { TeamsProps, TeamMember } from './types'
import { useTheme } from 'styled-components'
import UserSVG from '../../assets/user-svg.svg';
import CanadaSVG from '../../assets/canada-svg.svg';

const TEAM_MEMBERS: TeamMember[] = [
    {
        name: 'Ravinder Shokar',
        role: 'Founder',
        country: 'Canada',
        countryFlag: <img src={CanadaSVG} />
    },
    {
        name: 'Ethan Lee',
        role: 'Founder',
        country: 'Canada',
        countryFlag: <img src={CanadaSVG} />
    }
]

const Teams = (props: TeamsProps) => {
    const theme = useTheme();
    return <>
        <TeamsTitleSection>
            <div>
                <H2 style={{ color: theme.colors.secondary }}>Floating Fog</H2>
                <H2>Team</H2>
            </div>
        </TeamsTitleSection>
        <TeamContentSection id='team-section'>
            <div>
                {TEAM_MEMBERS.map((tm, index) => <TeamMemberComponent>
                    <img src={UserSVG} />
                    <div className='team-member'>
                        <TitleMedium style={{ color: 'black' }}>
                            {tm.name}
                        </TitleMedium>
                        <TitleMedium>
                            {tm.role}
                        </TitleMedium>
                    </div>
                    <div className='country'>
                        {tm.countryFlag}
                        <BodyLarge>
                            Canada
                        </BodyLarge>
                    </div>
                </TeamMemberComponent>)}
            </div>
        </TeamContentSection>
    </>
}

export default Teams