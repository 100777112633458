// src/utils/media.ts

import { generateMedia } from 'styled-media-query';


export const MediaQueries = {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1440px',
}

const customMedia = generateMedia(MediaQueries);

export default customMedia;
