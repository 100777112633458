import styled from "styled-components";
import { Section } from "../shared";

export const TeamsTitleSection = styled(Section)`
    background-color: ${props => props.theme.colors.tertiary};
    border: 1px solid ${props => props.theme.colors.tertiary}; 
    display: flex; 

    > div {
        flex: 1; 
        display: flex; 
        gap: 14px;
        justify-content: flex-start;
        flex-direction: row;
        max-width: 1500px;
    }
    
`

export const TeamContentSection = styled(Section)`
    background-color: ${props => props.theme.colors.secondary};
    padding-top: 100px; 
    padding-bottom: 100px; 
    display: flex;

    >div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 50px; 
        max-width: 1500px;
    }
`

export const TeamMember = styled.div`
    display: flex;
    align-items: center; 
    flex-direction: column; 
    gap: 10px;
    background-color: ${props => props.theme.colors.white};
    width: 400px; 
    max-width: 80%;
    padding-top: 20px; 
    padding-bottom: 20px; 

    img {
        height: 150px;
        width: 150px;
    }
    
    .team-member {
        display: flex; 
        flex-direction: column; 
        
        h1 {
            color: black; 
            text-align: center;
        }

        h1: last-child {
            opacity: .5;
        }
    }

    .country {
        display: flex; 
        flex-direction: row; 
        align-items: center; 
        justify-content: center;
        gap: 10px; 

        img {
            height: 50px;
            width: 50px; 
        }
        span {
            color: black;
        }
    }

`