import styled from "styled-components";
import { Section } from "../shared";
import customMedia from "../../utils/media";

export const AboutTitleSection = styled(Section)`
    background-color: ${props => props.theme.colors.secondary};
    display: flex;

    div {
        flex: 1;
        display: flex; 
        flex-direction: row;
        gap: 14px;
        justify-content: flex-start;
        max-width: 1500px;
    }
`;

export const AboutTextSection = styled(Section)`
    background-color: ${props => props.theme.colors.primary};
    display: flex; 
    
    > div {
        flex: 1;
        display: flex;
        flex-direction: row;
        max-width: 1500px;
        min-height: 80vh;
        align-items: center;
        gap: 100px; 

        ${customMedia.lessThan('xl')`
            flex-direction: column; 
            gap: 20px; 
            min-height: auto; 
            padding: 50px 0 50px 0;
        `}

        > div {
            display: flex; 
            flex-direction: column; 
            justify-content:center;
            align-items: flex-start;  
            gap:20px; 
        }

        img {
            width: 600px; 
            max-width: 80%;
        }
    }
`;