import styled from 'styled-components';
import customMedia, { MediaQueries } from '../utils/media';


interface ButtonContainerProps {
    stacked: boolean;
}
export const ButtonContainer = styled.div<ButtonContainerProps>`
    display: ${(props) => (props.stacked ? 'block' : 'flex')};
    flex-direction: ${props => props.stacked ? 'column' : 'row'};
    gap: 20px;  
`;


export const Section = styled.div`
    max-width: 100vw;
    display: flex; 
    justify-content: center; 
    align-items: center;
    padding: 0 5% 0 5%;
    border: none; 
`

export const PrimaryButton = styled.button`
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.fonts.labelLarge?.fontSize};
    font-weight: ${(props) => props.theme.fonts.labelLarge?.fontWeight};
    letter-spacing: ${(props) => props.theme.fonts.labelLarge?.letterSpacing};
    border-radius: 2%;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, opacity 0.3s, color 0.3s;

    &:hover {
        opacity: 0.6;
    }
`;

export const SecondaryButton = styled(PrimaryButton)`
    background-color: ${(props) => props.theme.colors.transperant};
    border: 2px solid ${(props) => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};

    &:hover {
        background-color: ${(props) => props.theme.colors.tertiary};
        color: ${(props) => props.theme.colors.white}
    }
`;

export const H1 = styled.h1`
    font-weight: ${(props) => props.theme.fonts.h1?.fontWeight};
    font-size: ${(props) => props.theme.fonts.h1?.fontSize};
    font-family: ${(props) => props.theme.fonts.h1?.fontFamily};
    margin: ${(props) => props.theme.fonts.h1?.margin};
    color: ${(props) => props.theme.colors.text};


    @media (max-width: ${MediaQueries.md}) {
        font-size: calc(${props => props.theme.fonts.h1?.fontSize} - 10pt);
    }
`;

export const H2 = styled.h2`
    font-weight: ${(props) => props.theme.fonts.h2?.fontWeight};
    font-size: ${(props) => props.theme.fonts.h2?.fontSize};
    font-family: ${(props) => props.theme.fonts.h2?.fontFamily};
    margin: ${(props) => props.theme.fonts.h2?.margin};
    color: ${(props) => props.theme.colors.text};

    @media (max-width: ${MediaQueries.md}) {
        font-size: calc(${props => props.theme.fonts.h2?.fontSize} - 5pt);
    }
`;

export const TitleLarge = styled.h1`
    font-size: ${(props) => props.theme.fonts.titleLarge?.fontSize};
    font-weight: ${(props) => props.theme.fonts.titleLarge?.fontWeight};
    letter-spacing: ${(props) => props.theme.fonts.titleLarge?.letterSpacing};
    margin: ${(props) => props.theme.fonts.titleLarge?.margin};
    color: ${(props) => props.theme.colors.text};
`;

export const TitleMedium = styled.h1`
    font-size: ${(props) => props.theme.fonts.titleMedium?.fontSize};
    font-weight: ${(props) => props.theme.fonts.titleMedium?.fontWeight};
    letter-spacing: ${(props) => props.theme.fonts.titleMedium?.letterSpacing};
    margin: ${(props) => props.theme.fonts.titleMedium?.margin};
    color: ${(props) => props.theme.colors.text};
`;




export const LabelLarge = styled.span`
    font-size: ${(props) => props.theme.fonts.labelLarge?.fontSize};
    font-weight: ${(props) => props.theme.fonts.labelLarge?.fontWeight};
    color: ${(props) => props.theme.colors.text};
`;

export const LabelMedium = styled.span`
    font-size: ${(props) => props.theme.fonts.labelMedium?.fontSize};
    font-weight: ${(props) => props.theme.fonts.labelMedium?.fontWeight};
    color: ${(props) => props.theme.colors.text};
`;

export const BodyLarge = styled.span`
    font-size: ${(props) => props.theme.fonts.bodyLarge?.fontSize};
    font-weight: ${(props) => props.theme.fonts.bodyLarge?.fontWeight};
    letter-spacing: ${(props) => props.theme.fonts.bodyLarge?.letterSpacing};
    color: ${(props) => props.theme.colors.text};
`;


export const BodyMedium = styled.span`
    font-size: ${(props) => props.theme.fonts.bodyMedium?.fontSize};
    color: ${(props) => props.theme.colors.text};
`;

