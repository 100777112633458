import { MyTheme } from "./styled";


const FONTS = 'Inter, Arial, sans-serif'

export const theme: MyTheme = {
    colors: {
        primary: '#2978A0',
        secondary: '#362073',
        tertiary: '#304D8B',
        white: '#FFFCFF',
        transperant: 'rgba(0,0,0,0)',
        background: '#f5f5f5',
        text: '#FFFCFF',
    },
    fonts: {
        h1: {
            fontWeight: 'bold',
            fontSize: '45pt',
            fontFamily: FONTS,
            margin: 0
        },
        h2: {
            fontWeight: 'bold',
            fontSize: '30pt',

        },
        titleLarge: {
            fontSize: '22pt',
            fontWeight: 'bold',
            letterSpacing: '4%',
            margin: 0,
        },
        titleMedium: {
            fontSize: '16pt',
            fontWeight: '500',
            letterSpacing: '4%',
            margin: 0,
        },
        labelLarge: {
            fontSize: '14pt',
            fontWeight: 'bold',
            letterSpacing: '4%',
        },
        labelMedium: {
            fontSize: '12pt',
            fontWeight: '500',
        },
        bodyLarge: {
            fontSize: '16pt',
            letterSpacing: '4%',
        },
        bodyMedium: {
            fontSize: '12pt',
        }
    },

};