import React from 'react'
import { HeroProps } from './types'
import { HeroContainer, HeroGraphic, HeroSection, TextContainer } from './styles'
import { ButtonContainer, H1, LabelLarge, PrimaryButton, SecondaryButton, TitleLarge } from '../shared';
import HeroImage from '../../assets/hero-image.png';

const Hero = (props: HeroProps) => {


    const onAboutPress = () => {
        const aboutSection = document.getElementById('about-section');
        if (aboutSection) aboutSection.scrollIntoView({ behavior: 'smooth' });
    }

    const onTeamPress = () => {
        const teamSection = document.getElementById('team-section');
        if (teamSection) teamSection.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <HeroSection>
            <HeroContainer>
                <TextContainer>
                    <H1>Software And Application Development</H1>
                    <TitleLarge>Contact and Collaborate</TitleLarge>
                    <ButtonContainer stacked={false}>
                        <PrimaryButton onClick={onAboutPress}>
                            About Us
                        </PrimaryButton>
                        <SecondaryButton onClick={onTeamPress}>
                            The Team
                        </SecondaryButton>
                    </ButtonContainer>
                </TextContainer>
                <HeroGraphic src={HeroImage} />
            </HeroContainer>
        </HeroSection>
    )
}

export default Hero